import { Button, Flex, Form, Input, message, Modal, Select, Space, Typography } from "antd";
import { useCreateConsumerPortalEmailAddressMutation } from "features/consumerPortalProfile/consumerPortalprofileAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";

function AddEmailAddressModal({ onClose }) {
  const [createEmailAddress] = useCreateConsumerPortalEmailAddressMutation();
  const { data: constants } = useFetchBackendConstantsQuery();

  const [form] = Form.useForm();

  const handleSave = async () => {
    const values = await form.validateFields();
    const result = await createEmailAddress(values);
    if ("data" in result) {
      message.success("Email address added successfully.", 2);
      onClose();
    }
  };

  return (
    <Modal
      maskClosable={false}
      title="Add Email Address"
      onCancel={onClose}
      open
      footer={
        <Button type="primary" htmlType="submit" onClick={handleSave}>
          Save
        </Button>
      }
    >
      <Form form={form} layout="vertical" name="add_email_address_form" onFinish={handleSave}>
        <Space.Compact>
          <Form.Item hasFeedback name="address">
            <Input style={{ width: 250 }} maxLength={100} placeholder="Email Address" />
          </Form.Item>
          <Form.Item hasFeedback name="type">
            <Select
              popupMatchSelectWidth={false}
              allowClear
              style={{ width: 120, marginRight: 16 }}
              placeholder="Type"
              options={constants?.emailTypes.map(({ display, value }) => ({
                value,
                label: display,
              }))}
            />
          </Form.Item>
          <Flex>
            <Form.Item name="isPrimary" valuePropName="checked">
              <Input type="checkbox" />
            </Form.Item>
            <Typography.Text style={{ lineHeight: 2, marginLeft: 8, marginTop: 1 }}>
              Default
            </Typography.Text>
          </Flex>
        </Space.Compact>
      </Form>
    </Modal>
  );
}

export default AddEmailAddressModal;

import { Button, Card, Col, Divider, Flex, Form, Input, message, Radio, Row, Select } from "antd";
import { DATE_FORMAT } from "common/constants";
import { AktDatePicker } from "components/aktDatePicker";
import { useCreatePaymentIntentMutation } from "features/consumerPortalPaymentHistory/paymentIntentsAPI";
import AddPaymentMethodForm from "features/consumerPortalPaymentMethods/components/addPaymentMethodForm";
import AddPaymentMethodModal from "features/consumerPortalPaymentMethods/components/addPaymentMethodModal";
import { useGetPaymentMethodsQuery } from "features/consumerPortalPaymentMethods/paymentMethodsAPI";
import {
  PAYMENT_VIEW_SCREEN_ENUMS,
  selectPaymentsSlice,
  setCurrentView,
} from "features/consumerPortalPaymentPage/paymentsSlice";
import moment from "moment-timezone";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const StyledDiv = styled(Flex)`
  margin-top: 24px;
  flex-direction: row-reverse;
`;

function FullPayment() {
  const [form] = Form.useForm();
  const [processPayment, { isLoading: isPosting }] = useCreatePaymentIntentMutation();
  const dispatch = useDispatch();
  const paymentsSlice = useSelector(selectPaymentsSlice);
  const { data: paymentMethods, isLoading: isPaymentMethodsLoading } = useGetPaymentMethodsQuery();
  const [isModalVisible, setModalVisible] = useState(false);

  const onFinish = async ({
    scheduledDate,
    paymentMethodUuid,
    isNewPaymentMethod,
    newPaymentMethod,
  }) => {
    const intents = [
      {
        totalAmount: paymentsSlice.totalAmount,
        scheduledDate,
        paymentMethodUuid,
        accountUuids: paymentsSlice.selectedAccounts.map((account) => account.uuid),
        paymentIntentType: "full",
        isPaymentPlan: false,
      },
    ];
    const newPaymentMethodFields = {};
    if (isNewPaymentMethod) {
      const { paymentMethod, ...newPaymentMethodValues } = newPaymentMethod;
      const [firstName, lastName] = (newPaymentMethodValues.name ?? "").split(" ");
      newPaymentMethodValues.firstName = firstName;
      newPaymentMethodValues.lastName = lastName;

      if (paymentMethod === "creditCard") {
        // post-transformation for credit card
        newPaymentMethodValues.cardExpMonth = moment(
          newPaymentMethodValues.cardExpDate,
          "MM/YY",
        ).format("MM");
        newPaymentMethodValues.cardExpYear = moment(
          newPaymentMethodValues.cardExpDate,
          "MM/YY",
        ).format("YYYY");
        newPaymentMethodFields.newCreditCard = newPaymentMethodValues;
      }
      if (paymentMethod === "bankAccount") {
        newPaymentMethodFields.newCreditCard = newPaymentMethodValues;
      }
    }
    const result = await processPayment({
      intents,
      ...newPaymentMethodFields,
      paymentIntentType: "full",
      executeIfReady: true,
    });
    if ("data" in result) {
      dispatch(setCurrentView(PAYMENT_VIEW_SCREEN_ENUMS.PaymentConfirmation));
    }
    if ("error" in result) {
      message.error("Failed to process payment");
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().startOf("day");
  };

  const initialValues = {
    totalAmount: paymentsSlice.totalAmount,
    scheduledDate: moment().format(DATE_FORMAT),
    isNewPaymentMethod: false,
    newPaymentMethod: {
      paymentMethod: "creditCard",
    },
  };

  return (
    <>
      <h3>Accounts Summary</h3>
      <Form form={form} onFinish={onFinish} layout="vertical" initialValues={initialValues}>
        <Row gutter={16}>
          <Col span={12}>
            <Card>
              <Form.Item
                label="Payment Amount"
                name="totalAmount"
                rules={[{ required: true, message: "Please enter the payment amount" }]}
              >
                <Input placeholder="Enter Amount" disabled />
              </Form.Item>
              <Form.Item
                label="Payment Date"
                name="scheduledDate"
                rules={[{ required: true, message: "Please select the payment date" }]}
              >
                <AktDatePicker type="date" disabledDate={disabledDate} />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: "Please enter confirmation Email" }]}
              >
                <Input placeholder="Enter Email" />
              </Form.Item>
            </Card>
            <Card>
              <h3>Payment Information</h3>
              <span>A full payment is a payment made for the total balance due.</span>
              {/* 
                  TODO: If there are 0 existing payment methods previously created by the Consumer,
                  then we should only show the 'New Payment Method' form.
               */}
              <Form.Item name="isNewPaymentMethod">
                <Radio.Group>
                  <Radio value>New Payment Method</Radio>
                  <Radio value={false}>Existing payment method</Radio>
                </Radio.Group>
              </Form.Item>
              <Divider />
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.isNewPaymentMethod !== currentValues.isNewPaymentMethod
                }
              >
                {({ getFieldValue }) => {
                  const isNewPaymentMethod = getFieldValue("isNewPaymentMethod");
                  return isNewPaymentMethod ? (
                    <AddPaymentMethodForm prefix="newPaymentMethod" />
                  ) : (
                    <Form.Item
                      name="paymentMethodUuid"
                      label="Payment Method"
                      rules={[{ required: true, message: "Please select a payment method" }]}
                    >
                      <Select
                        placeholder="Select Payment Method"
                        loading={isPaymentMethodsLoading}
                        options={paymentMethods?.map((method) => ({
                          value: method.uuid,
                          label: `${method.type} ending in ${
                            method.cardLast4Digits || method.bankAccountLast4Digits
                          }`,
                        }))}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <h3>Payment Information</h3>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
              <span>A full payment is a payment made for the total balance due.</span>
            </Card>
          </Col>
        </Row>
        <StyledDiv>
          <Button size="large" type="primary" htmlType="submit" loading={isPosting}>
            Confirm
          </Button>
        </StyledDiv>
      </Form>

      {isModalVisible && (
        <AddPaymentMethodModal
          onCancel={() => setModalVisible(false)}
          onOk={() => {
            setModalVisible(false);
            form.setFieldsValue({ isNewPaymentMethod: false });
          }}
          open={isModalVisible}
        />
      )}
    </>
  );
}

export default FullPayment;

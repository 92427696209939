import { PlusOutlined } from "@ant-design/icons";
import { Button, List } from "antd";
import AddPaymentMethodModal from "features/consumerPortalPaymentMethods/components/addPaymentMethodModal";
import PaymentMethodCard from "features/consumerPortalPaymentMethods/components/paymentMethodCard";
import { useGetPaymentMethodsQuery } from "features/consumerPortalPaymentMethods/paymentMethodsAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledButton = styled(Button)`
  margin-top: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #f5f5f5;
  height: auto;
  width: calc(100% - 16px);
`;
const StyledHeader = styled.h3`
  margin-bottom: 16px;
`;

export default function PaymentMethodsPage() {
  const { data: paymentMethods, isLoading } = useGetPaymentMethodsQuery();
  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <>
      <StyledHeader>Payment Methods</StyledHeader>
      <List
        dataSource={paymentMethods}
        loading={isLoading}
        renderItem={(paymentMethod) => (
          <PaymentMethodCard hasDelete paymentMethod={paymentMethod} />
        )}
        bordered={paymentMethods?.length <= 0}
      />
      <StyledButton
        type="dashed"
        block
        onClick={() => setModalVisible(true)}
        icon={<PlusOutlined />}
      >
        Add Payment Method
      </StyledButton>
      {isModalVisible && (
        <AddPaymentMethodModal
          onCancel={() => setModalVisible(false)}
          onOk={() => setModalVisible(false)}
          open={isModalVisible}
        />
      )}
    </>
  );
}

import { ConsumerPortalPaymentMethods } from "features/consumerPortalPaymentMethods";
import PageLayout from "layouts/consumerPortal/pageLayout";

export default function PaymentMethodsPage() {
  return (
    <PageLayout>
      <ConsumerPortalPaymentMethods />
    </PageLayout>
  );
}

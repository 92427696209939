import ConsumerPortalProfile from "features/consumerPortalProfile/consumerPortalProfile";
import PageLayout from "layouts/consumerPortal/pageLayout";

function ProfilePage() {
  return (
    <PageLayout>
      <ConsumerPortalProfile />
    </PageLayout>
  );
}

export default ProfilePage;

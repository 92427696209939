import { Card } from "antd";
import PaymentScheduleCard from "features/consumerPortalPaymentPage/components/paymentScheduleCard";
import {
  PAYMENT_VIEW_SCREEN_ENUMS,
  setCurrentView,
} from "features/consumerPortalPaymentPage/paymentsSlice";
import Content from "layouts/consumerPortal/content";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledDiv = styled.div`
  margin-top: 24px;
  margin-left: 16px;
  & span {
    margin-left: 12px;
  }
`;

function SelectPaymentOption() {
  const dispatch = useDispatch();
  const onSelect = (paymentMethod) => {
    const action = {
      full: PAYMENT_VIEW_SCREEN_ENUMS.SetupFullPayment,
      partial: PAYMENT_VIEW_SCREEN_ENUMS.SetupPartialPayment,
      plan: PAYMENT_VIEW_SCREEN_ENUMS.SetupPaymentPlanConfiguration,
    }[paymentMethod];
    dispatch(setCurrentView(action));
  };

  return (
    <Content>
      <h3>Select Your Payment Option</h3>
      <Card>
        <PaymentScheduleCard type="full" onSelect={onSelect} />
        <PaymentScheduleCard type="partial" onSelect={onSelect} />
        <PaymentScheduleCard type="plan" onSelect={onSelect} />
        <StyledDiv>
          <div style={{ fontStyle: "italic", textAlign: "center" }}>
            By authorizing this transaction, you understand and agree that all current and postdated
            payments will be applied to the balance of the debt. You also understand that making a
            payment may not prevent the remaining debt from being charged-off in accordance to the
            terms of the contract executed between you and the creditor. In the event of a
            charge-off, all payment arrangements may be voided. Visit{" "}
            <Link to="/contact">Contact Us</Link> if you have any questions.
          </div>
        </StyledDiv>
      </Card>
    </Content>
  );
}

export default SelectPaymentOption;

import { ConsumerPortalPaymentHistory } from "features/consumerPortalPaymentHistory";
import PageLayout from "layouts/consumerPortal/pageLayout";

function PaymentHistoryPage() {
  return (
    <PageLayout>
      <ConsumerPortalPaymentHistory />
    </PageLayout>
  );
}

export default PaymentHistoryPage;

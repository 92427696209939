import { Layout } from "antd";
import Header from "layouts/consumerPortal/header";
import AppMenu from "layouts/consumerPortal/menu";
import styled from "styled-components";

const StyledContent = styled(Layout.Content)`
  padding: 8px 24px;
  min-height: 280px;
`;

function PageLayout({ children }) {
  return (
    <Layout>
      <Header />
      <Layout>
        <AppMenu />
        <StyledContent>{children}</StyledContent>
      </Layout>
    </Layout>
  );
}

export default PageLayout;

import {
  HomeFilled,
  MailFilled,
  MailTwoTone,
  PhoneFilled,
  PhoneTwoTone,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Card, Flex, List, Row, Tag } from "antd";
import { renderAddress, renderPhoneNumber } from "common/utils";
import { useFetchMeQuery } from "features/auth/authAPI";
import AddAddressModal from "features/consumerPortalProfile/components/addAddressModal";
import AddEmailAddressModal from "features/consumerPortalProfile/components/addEmailAddressModal";
import AddPhoneNumberModal from "features/consumerPortalProfile/components/addPhoneNumberModal";
import {
  useGetConsumerPortalAddressesQuery,
  useGetConsumerPortalEmailAddressesQuery,
  useGetConsumerPortalPhoneNumbersQuery,
} from "features/consumerPortalProfile/consumerPortalprofileAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useState } from "react";

function ConsumerPortalProfile() {
  const [isPhoneModalVisible, setIsPhoneModalVisible] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [isAddressModalVisible, setIsAddressModalVisible] = useState(false);

  const { data: phoneNumbers = [], isLoading: isPhoneNumbersLoading } =
    useGetConsumerPortalPhoneNumbersQuery();
  const { data: emailAddresses = [], isLoading: isEmailAddressesLoading } =
    useGetConsumerPortalEmailAddressesQuery();
  const { data: addresses = [], isLoading: isAddressesLoading } =
    useGetConsumerPortalAddressesQuery();
  const { data: constants } = useFetchBackendConstantsQuery();

  const { data: me } = useFetchMeQuery();

  return (
    <>
      <Flex vertical align="center" justify="center">
        <h2>{me?.name}</h2>
        <Card
          title={
            <Flex
              style={{
                marginBottom: 12,
              }}
              align="center"
              justify="space-between"
            >
              <div>
                <Flex align="center" style={{ marginBottom: -24 }}>
                  <PhoneFilled style={{ marginRight: 8 }} />
                  <h4>Phone Numbers</h4>
                </Flex>
                <span style={{ fontSize: 12, fontWeight: "normal", color: "grey" }}>
                  Make sure to keep your primary phone number up-to-date.
                </span>
              </div>
              <Button
                style={{ marginTop: 12 }}
                shape="round"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsPhoneModalVisible(true)}
              >
                Add Phone Number
              </Button>
            </Flex>
          }
          style={{ width: 800, marginBottom: 12 }}
        >
          <List
            dataSource={phoneNumbers}
            renderItem={(item) => (
              <Row style={{ marginBottom: 8 }}>
                <PhoneTwoTone twoToneColor="#52c41a" style={{ marginRight: 4 }} />
                <div>
                  {renderPhoneNumber(item)} {item.isPrimary && <Tag>PRIMARY</Tag>}
                </div>
              </Row>
            )}
            loading={isPhoneNumbersLoading}
          />
        </Card>
        <Card
          title={
            <Flex
              style={{
                marginBottom: 12,
              }}
              align="center"
              justify="space-between"
            >
              <div>
                <Flex align="center" style={{ marginBottom: -24 }}>
                  <MailFilled style={{ marginRight: 8 }} />
                  <h4>Email Addresses</h4>
                </Flex>
                <span style={{ fontSize: 12, fontWeight: "normal", color: "grey" }}>
                  Make sure to keep your primary email address up-to-date.
                </span>
              </div>
              <Button
                style={{ marginTop: 12 }}
                shape="round"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsEmailModalVisible(true)}
              >
                Add Email
              </Button>
            </Flex>
          }
          style={{ width: 800, marginBottom: 12 }}
        >
          <List
            dataSource={emailAddresses}
            renderItem={(item) => (
              <Row style={{ marginBottom: 8 }}>
                <MailTwoTone twoToneColor="blue" style={{ marginRight: 4 }} />
                <div>
                  {item.address} {item.isPrimary && <Tag>PRIMARY</Tag>}
                </div>
              </Row>
            )}
            loading={isEmailAddressesLoading}
          />
        </Card>
        <Card
          title={
            <Flex
              style={{
                marginBottom: 12,
              }}
              align="center"
              justify="space-between"
            >
              <div>
                <Flex align="center" style={{ marginBottom: -24 }}>
                  <HomeFilled style={{ marginRight: 8 }} />
                  <h4>Addresses</h4>
                </Flex>
                <span style={{ fontSize: 12, fontWeight: "normal", color: "grey" }}>
                  Make sure to keep your primary address up-to-date.
                </span>
              </div>
              <Button
                style={{ marginTop: 12 }}
                shape="round"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsAddressModalVisible(true)}
              >
                Add Address
              </Button>
            </Flex>
          }
          style={{ width: 800, marginBottom: 12 }}
        >
          <List
            dataSource={addresses}
            renderItem={(item) => (
              <Row style={{ marginBottom: 8 }}>
                <MailTwoTone twoToneColor="blue" style={{ marginRight: 4 }} />
                <div>
                  {renderAddress(item, constants?.states)} {item.isPrimary && <Tag>PRIMARY</Tag>}
                </div>
              </Row>
            )}
            loading={isAddressesLoading}
          />
        </Card>
      </Flex>
      {isAddressModalVisible && <AddAddressModal onClose={() => setIsAddressModalVisible(false)} />}
      {isEmailModalVisible && (
        <AddEmailAddressModal onClose={() => setIsEmailModalVisible(false)} />
      )}
      {isPhoneModalVisible && <AddPhoneNumberModal onClose={() => setIsPhoneModalVisible(false)} />}
    </>
  );
}

export default ConsumerPortalProfile;

import { Button, Flex, Form, Input, Modal, Select, Typography } from "antd";
import { useCreateConsumerPortalAddressMutation } from "features/consumerPortalProfile/consumerPortalprofileAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";

function AddAddressModal({ onClose }) {
  const { data: constants } = useFetchBackendConstantsQuery();
  const [createAddress] = useCreateConsumerPortalAddressMutation();

  const [form] = Form.useForm();

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const handleSave = async () => {
    const values = await form.validateFields();
    const result = await createAddress(values);
    if ("data" in result) {
      onClose();
    }
  };

  return (
    <Modal
      maskClosable={false}
      title="Add Address"
      onCancel={onClose}
      open
      footer={
        <Button type="primary" htmlType="submit" onClick={handleSave}>
          Save
        </Button>
      }
    >
      <Form form={form} layout="vertical" name="add_address_form" onFinish={handleSave}>
        <Form.Item hasFeedback name="type">
          <Select
            popupMatchSelectWidth={false}
            allowClear
            style={{ width: 250 }}
            placeholder="Type"
            options={constants?.addressTypes.map(({ display, value }) => ({
              value,
              label: display,
            }))}
          />
        </Form.Item>
        <Form.Item hasFeedback name="address_1" rules={[{ required: true }]}>
          <Input maxLength={100} placeholder="Address 1" />
        </Form.Item>
        <Form.Item hasFeedback name="address_2">
          <Input maxLength={100} placeholder="Address 2" />
        </Form.Item>
        <Form.Item hasFeedback name="city" rules={[{ required: true }]}>
          <Input maxLength={100} placeholder="City" />
        </Form.Item>
        <Form.Item hasFeedback name="state" rules={[{ required: true }]}>
          <Select
            popupMatchSelectWidth={false}
            placeholder="State"
            options={constants?.states.map(({ display, value }) => ({
              value,
              label: display,
            }))}
            filterOption={filterOption}
            showSearch
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          name="zipCode"
          rules={[
            {
              required: true,
            },
            {
              pattern: /^\d{5}(-\d{4})?$/,
              message: "Must be a valid zip code",
            },
          ]}
        >
          <Input maxLength={5} placeholder="Zip Code" />
        </Form.Item>
        <Flex>
          <Form.Item name="isPrimary" valuePropName="checked">
            <Input type="checkbox" />
          </Form.Item>
          <Typography.Text style={{ lineHeight: 2, marginLeft: 8, marginTop: 1 }}>
            Default
          </Typography.Text>
        </Flex>
      </Form>
    </Modal>
  );
}

export default AddAddressModal;

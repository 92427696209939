import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Col, Descriptions, Popover, Row, Table } from "antd";
import { formatCurrency } from "common/utils";
import currency from "currency.js";
import { useGetConsumerPortalAccountsQuery } from "features/consumerPortalDashboard/accountsAPI";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledDiv = styled.div`
  margin-top: 24px;
  margin-left: 16px;
  & span {
    margin-left: 12px;
  }
`;
const StyledInfoValue = styled.div`
  margin-left: 4px;
  margin-right: 4px;
`;
function DashboardPage() {
  const { data: accounts, isLoading } = useGetConsumerPortalAccountsQuery();

  const totalBalance = accounts
    ? formatCurrency(
        accounts.reduce(
          (acc, account) => acc.add(account.totalBalance),
          currency(0, { precision: 2 }),
        ),
      )
    : "$0.00";

  const columns = [
    { title: "Creditor", dataIndex: "currentCreditorName", key: "currentCreditorName" },
    { title: "Account Number", dataIndex: "externalId", key: "externalId" },
    {
      title: "Current Balance",
      dataIndex: "totalBalance",
      key: "totalBalance",
      render: (text, record) => {
        const balanceItems = [
          {
            key: "principal",
            label: "Principal",
            value: formatCurrency(record.principalBalance),
          },
          {
            key: "interest",
            label: `Interest (${currency(0.2, { precision: 4 }).multiply(100).value}%)`,
            value: formatCurrency(record.interestBalance),
          },
          {
            key: "totalFees",
            label: "Fees",
            value: formatCurrency(record.totalFeesBalance),
          },
          // {
          //   key: "originalTotal",
          //   label: <strong>Total</strong>,
          //   value: <strong>{formatCurrency(text)}</strong>,
          // },
        ];
        const totalBalanceContent = (
          <Col>
            <Descriptions bordered column={1} size="small">
              {balanceItems.map((item) => (
                <Descriptions.Item key={item.key} label={item.label}>
                  {item.value}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </Col>
        );
        return (
          <Row align="middle">
            <StyledInfoValue>{formatCurrency(text)}</StyledInfoValue>
            <Popover
              content={totalBalanceContent}
              title="Current Balance Breakdown"
              trigger="click"
              placement="right"
            >
              <InfoCircleOutlined />
            </Popover>
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <h3>Accounts Summary</h3>
      <Card>
        <Table
          pagination={false}
          dataSource={accounts}
          columns={columns}
          loading={isLoading}
          rowKey="uuid"
        />
        <StyledDiv>
          <strong>Current Balance</strong>
          <span>{totalBalance}</span>
          <div style={{ fontStyle: "italic" }}>
            For questions regarding your account(s), please visit the{" "}
            <Link to="/contact">Contact Us</Link> page to find your agency's contact information.
          </div>
        </StyledDiv>
      </Card>
    </>
  );
}

export default DashboardPage;

import { ConsumerPortalDashboard } from "features/consumerPortalDashboard";
import PageLayout from "layouts/consumerPortal/pageLayout";

function DashboardPage() {
  return (
    <PageLayout>
      <ConsumerPortalDashboard />
    </PageLayout>
  );
}

export default DashboardPage;

import { ReloadOutlined } from "@ant-design/icons";
import { Button, Empty, Flex, Skeleton } from "antd";
import { IFrame } from "components/iFrame";
import { useFetchPaymentHistoryQuery } from "features/consumerPortalPaymentHistory/paymentIntentsAPI";
import styled from "styled-components";

const StyledIFrame = styled(IFrame)`
  width: 100%;
  height: 75vh;
  border: 0;
`;

function PaymentHistoryPage() {
  const {
    data: paymentHistoryReport,
    isLoading,
    isError,
    refetch: refetchPaymentHistory,
  } = useFetchPaymentHistoryQuery();

  return (
    <>
      <h3>Payment History</h3>
      {isLoading && <Skeleton active />}
      {isError && (
        <Empty
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100% - 20px - 2em)",
          }}
          description={
            <Flex vertical justify="center" align="center">
              <h3 style={{ marginBottom: 0, marginTop: 0, fontWeight: "normal" }}>
                Oops, something went wrong!
              </h3>
              <Button type="link" onClick={refetchPaymentHistory} icon={<ReloadOutlined />}>
                Reload
              </Button>
            </Flex>
          }
        />
      )}
      {paymentHistoryReport && (
        <StyledIFrame title="Payment History" src={`${paymentHistoryReport.url}#bordered=false`} />
      )}
    </>
  );
}

export default PaymentHistoryPage;

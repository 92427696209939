import { CreditCardOutlined } from "@ant-design/icons";
import { Flex, Form, Input, Radio, Row, Select, Space } from "antd";
import { AktDatePicker } from "components/aktDatePicker";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import moment from "moment-timezone";
import styled from "styled-components";

const StyledInput = styled(Input)`
  width: 250px;
`;

const StyledFlex = styled(Flex)`
  margin-left: 20px;
  margin-top: 8px;
`;

const StyledSelect = styled(Select)`
  width: 250px;
`;

const StyledVerticalFlex = styled(Flex)`
  border: 1px solid #f0f0f0;
  padding: 16px;
  width: 616px;
`;

function AddPaymentMethodForm({ prefix }) {
  const { data: constants } = useFetchBackendConstantsQuery();

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const disabledDate = (input) => {
    // Can not select days before today.
    return input && input < moment().startOf("day");
  };

  return (
    <>
      {/* <h5>Select Method</h5> */}
      <Form.Item name={[prefix, "paymentMethod"]} rules={[{ required: true }]}>
        <Radio.Group>
          <Space direction="vertical">
            <StyledVerticalFlex vertical>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prefix
                    ? prevValues[prefix].paymentMethod !== currentValues[prefix].paymentMethod
                    : prevValues.paymentMethod !== currentValues.paymentMethod
                }
              >
                {({ getFieldValue }) => {
                  const paymentMethod = getFieldValue([prefix, "paymentMethod"]);
                  return (
                    <>
                      <Radio
                        style={{
                          fontWeight: paymentMethod === "creditCard" ? "bold" : "normal",
                        }}
                        value="creditCard"
                      >
                        New Credit Card or Debit Card
                      </Radio>
                      {paymentMethod === "creditCard" && (
                        <StyledFlex vertical>
                          <Form.Item
                            label="Card Number"
                            name={[prefix, "cardNumber"]}
                            rules={[{ required: true }]}
                          >
                            <Input
                              width="100%"
                              prefix={<CreditCardOutlined />}
                              placeholder="0000 0000 0000 0000"
                            />
                          </Form.Item>
                          <Space size="large">
                            <Form.Item label="Expiration Date">
                              <Form.Item
                                noStyle
                                name={[prefix, "cardExpDate"]}
                                rules={[{ required: true }]}
                              >
                                <AktDatePicker
                                  picker="month"
                                  placeholder="MM/YY"
                                  suffixIcon={null}
                                  format="MM/YY"
                                  disabledDate={disabledDate}
                                />
                              </Form.Item>
                            </Form.Item>
                            <Form.Item
                              label="Security Code"
                              name={[prefix, "cvv"]}
                              rules={[{ required: true }]}
                            >
                              <StyledInput placeholder="CVV/CVC" />
                            </Form.Item>
                          </Space>
                          <Form.Item
                            label="Cardholder Name"
                            name={[prefix, "name"]}
                            rules={[{ required: true }]}
                          >
                            <StyledInput placeholder="Cardholder Name" />
                          </Form.Item>
                          <Form.Item>
                            <strong>Billing Details</strong>
                          </Form.Item>
                          {/* <Form.Item label="Type" name={[prefix, "type"]}>
                            <StyledSelect
                              allowClear
                              placeholder="Type"
                              options={constants?.addressTypes.map(({ display, value }) => ({
                                value,
                                label: display,
                              }))}
                            />
                          </Form.Item> */}
                          <Form.Item>
                            <Row>
                              <Form.Item
                                label="Address1"
                                name={[prefix, "address_1"]}
                                rules={[{ required: true }]}
                              >
                                <Input maxLength={100} placeholder="Address 1" />
                              </Form.Item>
                              <Form.Item label="Address2" name={[prefix, "address_2"]}>
                                <Input maxLength={100} placeholder="Address 2" />
                              </Form.Item>
                            </Row>
                          </Form.Item>
                          <Form.Item>
                            <Row wrap={false}>
                              <Form.Item
                                label="City"
                                name={[prefix, "city"]}
                                rules={[{ required: true }]}
                              >
                                <Input maxLength={100} placeholder="City" />
                              </Form.Item>
                              <Form.Item
                                label="State"
                                name={[prefix, "state"]}
                                rules={[{ required: true }]}
                              >
                                <Select
                                  popupMatchSelectWidth={false}
                                  placeholder="State"
                                  options={constants?.states.map(({ display, value }) => ({
                                    value,
                                    label: display,
                                  }))}
                                  filterOption={filterOption}
                                  showSearch
                                />
                              </Form.Item>
                              <Form.Item
                                name={[prefix, "zipCode"]}
                                label="Zip Code"
                                rules={[
                                  {
                                    required: true,
                                  },
                                  {
                                    pattern: /^\d{5}(-\d{4})?$/,
                                    message: "Must be a valid zip code",
                                  },
                                ]}
                              >
                                <Input maxLength={5} placeholder="Zip Code" />
                              </Form.Item>
                            </Row>
                          </Form.Item>
                        </StyledFlex>
                      )}
                    </>
                  );
                }}
              </Form.Item>
            </StyledVerticalFlex>
          </Space>

          <Space direction="vertical">
            <StyledVerticalFlex vertical>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prefix
                    ? prevValues[prefix].paymentMethod !== currentValues[prefix].paymentMethod
                    : prevValues.paymentMethod !== currentValues.paymentMethod
                }
              >
                {({ getFieldValue }) => {
                  const paymentMethod = getFieldValue([prefix, "paymentMethod"]);
                  return (
                    <>
                      <Radio
                        value="bankAccount"
                        style={{
                          fontWeight: paymentMethod === "bankAccount" ? "bold" : "normal",
                        }}
                      >
                        Bank Account ACH
                      </Radio>
                      {paymentMethod === "bankAccount" && (
                        <StyledFlex vertical>
                          <Space size="large">
                            <Form.Item
                              label="Bankholder Name"
                              name={[prefix, "name"]}
                              rules={[{ required: true }]}
                            >
                              <StyledInput placeholder="Bankholder Name" />
                            </Form.Item>
                          </Space>
                          <Space size="large">
                            <Form.Item
                              label="Bank Account Type"
                              name={[prefix, "bankAccountType"]}
                              rules={[{ required: true }]}
                            >
                              <Select
                                placeholder="Bank Account Type"
                                options={[
                                  {
                                    value: "checking",
                                    label: "Checking",
                                  },
                                  {
                                    value: "savings",
                                    label: "Savings",
                                  },
                                ]}
                              />
                            </Form.Item>
                          </Space>
                          <Space size="large">
                            <Form.Item
                              label="Bank Name"
                              name={[prefix, "bankAccountName"]}
                              rules={[{ required: true }]}
                            >
                              <Input placeholder="Bank Name" />
                            </Form.Item>
                            <Form.Item
                              label="Routing Number"
                              name={[prefix, "bankRoutingNumber"]}
                              rules={[{ required: true }]}
                            >
                              <Input placeholder="Routing Number" />
                            </Form.Item>
                          </Space>
                          <Space size="large">
                            <Form.Item
                              label="Account Number"
                              name={[prefix, "bankAccountNumber"]}
                              rules={[{ required: true }]}
                            >
                              <Input placeholder="Account Number" />
                            </Form.Item>
                            <Form.Item
                              label="Confirm Account Number"
                              name={[prefix, "confirmBankAccountNumber"]}
                              rules={[{ required: true }]}
                            >
                              <Input placeholder="Account Number" />
                            </Form.Item>
                          </Space>
                        </StyledFlex>
                      )}
                    </>
                  );
                }}
              </Form.Item>
            </StyledVerticalFlex>
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  );
}

export default AddPaymentMethodForm;

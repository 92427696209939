import { ConsumerPortalContactUs } from "features/consumerPortalContactUs";
import PageLayout from "layouts/consumerPortal/pageLayout";

function ContactUsPage() {
  return (
    <PageLayout>
      <ConsumerPortalContactUs />
    </PageLayout>
  );
}

export default ContactUsPage;

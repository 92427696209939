import { ConsumerPortalPaymentPage } from "features/consumerPortalPaymentPage";
import PageLayout from "layouts/consumerPortal/pageLayout";

function MakePaymentPage() {
  return (
    <PageLayout>
      <ConsumerPortalPaymentPage />
    </PageLayout>
  );
}

export default MakePaymentPage;

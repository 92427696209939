import DocumentsTable from "features/documentsTable/consumerPortal/documentsTable";
import PageLayout from "layouts/consumerPortal/pageLayout";

function DocumentsPage() {
  return (
    <PageLayout>
      <DocumentsTable />
    </PageLayout>
  );
}

export default DocumentsPage;

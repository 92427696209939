import {
  DashboardOutlined,
  DollarOutlined,
  FileOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledLayoutSider = styled(Layout.Sider)`
  overflow: auto;
  min-height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  padding-top: 6px;
  height: 100%;
`;

function AppMenu(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);

  const items = [
    {
      key: "dashboard",
      icon: <DashboardOutlined />,
      title: "Dashboard",
      label: <Link to="/dashboard">Dashboard</Link>,
    },
    // {
    //   key: "accounts",
    //   icon: <UserOutlined />,
    //   title: "Accounts",
    //   label: <Link to="/accounts">Accounts</Link>,
    // },
    {
      key: "documents",
      icon: <FileOutlined />,
      title: "Documents",
      label: <Link to="/documents">Documents</Link>,
    },
    {
      key: "payments",
      icon: <DollarOutlined />,
      title: "Payments",
      label: "Payments",
      children: [
        {
          key: "payments/make-payment",
          title: "Make Payment",
          label: <Link to="/payments/make-payment">Make Payment</Link>,
        },
        {
          key: "payments/payment-history",
          title: "Payment History",
          label: <Link to="/payments/payment-history">Payment History</Link>,
        },
        {
          key: "payments/payment-methods",
          title: "Payment Methods",
          label: <Link to="/payments/payment-methods">Payment Methods</Link>,
        },
        {
          key: "payments/upcoming-payments",
          title: "Upcoming Payments",
          label: <Link to="/payments/upcoming-payments">Upcoming Payments</Link>,
        },
      ],
    },
    {
      key: "contact",
      icon: <QuestionCircleOutlined />,
      title: "Contact Us",
      label: <Link to="/contact">Contact Us</Link>,
    },
  ];

  const pathway = location.pathname.substring(1); // removes leading slash
  const selectedKeys = [pathway ?? items[0].key]; // select based on pathway or default to first item
  useEffect(() => {
    const paths = location.pathname.split("/").filter((p) => p);
    if (paths.length > 0) {
      // setSelectedKeys([paths[0]]);
      setOpenKeys(paths.length > 1 ? [paths[0]] : []);
    }
  }, [location]);

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };
  return (
    <StyledLayoutSider
      collapsible
      width={225}
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <Menu
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        theme="dark"
        mode="inline"
        items={items}
        selectedKeys={selectedKeys}
      />
    </StyledLayoutSider>
  );
}

export default AppMenu;

import { ConsumerPortalUpcomingPayments } from "features/consumerPortalUpcomingPayments";
import PageLayout from "layouts/consumerPortal/pageLayout";

export default function UpcomingPaymentsPage() {
  return (
    <PageLayout>
      <ConsumerPortalUpcomingPayments />
    </PageLayout>
  );
}

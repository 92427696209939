import { ExportOutlined, HomeOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Divider, Empty, Flex, Space, Typography } from "antd";
import styled from "styled-components";

const StyledDivider = styled(Divider)`
  background-color: #dbdbdb;
`;

const { Link } = Typography;
function ConsumerPortalContactUs() {
  return (
    // Hacking the AntD Empty component to make use of the stock image
    <Empty
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
      description={
        <Flex vertical justify="center" align="center">
          <h1 style={{ marginBottom: 0, marginTop: 0, fontWeight: "normal" }}>Contact Us</h1>
          <p style={{ color: "gray" }}>
            If you have any questions or concerns, please feel free to contact your agency using the
            information below.
          </p>
          <br />

          <Flex vertical justify="center" align="center">
            <Link href="https://www.google.com" target="_blank" rel="noopener noreferrer">
              <Flex align="center">
                <h5
                  style={{ fontWeight: "normal", marginTop: 5, marginRight: 4, color: "#1890ff" }}
                >
                  Test Agency
                </h5>
                <ExportOutlined />
              </Flex>
            </Link>
            <Flex align="center">
              <Space size="small">
                <HomeOutlined />
                <span>123 Main St Anytown, USA 12345</span>
              </Space>
              <StyledDivider type="vertical" />
              <Space size="small">
                <PhoneOutlined />
                <span>(123)-456-7890</span>
              </Space>
              <StyledDivider type="vertical" />
              <Space size="small">
                <MailOutlined />
                <span>agency@gmail.com</span>
              </Space>
            </Flex>
          </Flex>
        </Flex>
      }
    />
  );
}

export default ConsumerPortalContactUs;

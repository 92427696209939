import ChangePassword from "features/auth/components/changePasswordForm";
import LoginPageLayout from "layouts/consumerPortal/loginPageLayout";

function ChangePasswordPage() {
  return (
    <LoginPageLayout>
      <ChangePassword />
    </LoginPageLayout>
  );
}

export default ChangePasswordPage;
